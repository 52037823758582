'use strict';

angular.module('windmanagerApp')
  .directive('listSite', function() {
    return {
      templateUrl: 'components/directives/site/listSite/listSite.html',
      restrict: 'EA',
      controller: 'ListSiteCtrl',
      controllerAs: 'listSite',
      link: function(scope, element, attrs) {
      }
    };
  });
